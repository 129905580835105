.topLevelContainer{
    width: 100%;
}

.flexContainer{
    display:flex;
}

.aboutContainer {
    box-sizing: border-box;
    padding-left: min(89px, 4.6%);
    padding-right: min(89px, 4.6%);
    width: 72%;
    max-width: 1361px;
    margin: auto;
    background-color: transparent;
    border-radius: 12px;
    font-size: min(18px, 4vw);
    font-family: "Inter", sans-serif;
    color: #333C53;
}

.aboutLink{
    color: #814dc4;
    cursor: pointer;
}

.aboutLandingTileContainer{
    padding-top: 64px;
    box-sizing: border-box;
    width: 100%;
    background: linear-gradient(to right bottom, #C0C7FE, #BE8BFF) !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutLandingTileInnerContainer{
    max-width: 1361px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.aboutText{
    
    width: 100%;
    margin-bottom: 20px;
    margin-top: 0px;
}


.title{
    color: #FAFAFA;
    font-family: DM Sans, sans-serif;
    font-weight: bold;
    font-size: max(min(60px, 5vw), 46px);
    margin: 45px auto;
    text-align: center;
}

.subtitle{
    margin: 30px auto;
    color: #333C53;
    text-align: center;
    font-family: DM, sans-serif;
    max-width: 850px;
    font-weight: 600;
    font-size: min(25px, 5vw);
}

@media screen and (max-width:540px) {

    .aboutContainer{
        font-size: min(18px, 4vw);
        padding: 0px;
    }
    .aboutContainer{
        width: 90%;
        display: inline-block;
        content: "";
    }


}



@media screen and (max-width:1150px) {
    .aboutContainer{
        text-align: justify;
    }
    .landingTileContainer{
        padding-top: 60px;
    }
}


@media screen and (max-width:900px){


    .landingTileInnerContainer{
        width: 80%;
    }

    .landingTileContainer{
        padding-top: 40px;
    }
}
