.surrogateBody{
    min-height: calc(100vh + 306px);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.divider{
    width: 100%;
    height: 150px;
}