.faqContainer{
    width: 90%;
    max-width: 680px;
    margin: auto;
    margin-top: 44px;
    font-family: 'Inter', sans-serif;
    color: #333C53;
}

.linkText a{
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #7482FF;
    width: auto;
}

.header{
    font-size: 40px;
    margin-bottom: 21px;
}

.iconButton{
    color: #333C53;
}

.divider{
    margin-top: 8px !important;
    color: #A7B3D1 !important;
}

.categoryTopBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.categoryContainer{
    display: flex;
    flex-direction: column;
    gap: 29px;
    font-size: 24px;
    font-weight: 400;
}

.answer{
    white-space: pre-wrap;
}

.categoryHeader{
}

.question{
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
    font-color: #818EB1;
}

.qanda{
    margin-bottom:34px;
    font-size: 14px;
}