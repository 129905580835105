.footerLinks>:hover{
    text-decoration: underline;
    text-underline-position: under;
}

.legal{
    text-decoration: none;
    color: #747f9f;
    cursor: pointer !important;
}

.legal:hover{
    color: #404758 !important;
}

.socials{
    color: #404758;
}
.socials:hover{
    background-color: inherit !important;
    color: #535968;
}

.footerLink{
    cursor: pointer;
    text-decoration: none;
    color: #404758;
}

