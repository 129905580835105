.sideBarItem{
    padding-bottom: 10px !important;
}

.container{
    margin: auto;
    margin-top: 35px;
    width: 72% !important;
    max-width: 1361px !important;
    display: inline-flex;

}

.sideBar{
    font-family: 'Inter', sans-serif;
    width: 331px;
    margin: 0;
    margin-right: auto;
    display: flex;
    flex-direction: column;

}

.sideBarTile{
    border-radius: 11px !important;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 35px;
    background-color: white;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.userGenText{
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    margin-top: 6px;
    color: #818EB1;
}

.infoContainer{
    font-size: 13.68px;
    margin-bottom: 10px;
    display: flex;
}

.salesInfoContainer{
    font-size: 13.68px;
    display: flex;
}

.salesContainer{
}

.walletButton{
    align-self: flex-start;
    color: #7482FF;
    cursor: pointer;
    text-decoration: none;
}

.infoTextContainer{
    color: #333C53;
    width: 50%;
    float: right;
    text-align: right;
}

.infoText{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 15px;
}

.infoLabel{
    margin-bottom: 15px;
}

.infoLabelCourseCode{
    font-weight: 600;
}

.infoLabelContainer{
    width: 50%;
    color: #818EB1;
}

.topLevelContainer{
    width: 100%;
    display: flex;
}

.userGenLabel{
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    color: #333C53;
}

.descriptionLabel{
    margin-top:15px;
}

.divider{
    color: #818EB1;
    width: 331px !important;
    margin-left:-35px !important;
}

.documentPreview{
    width: 993.98px !important;
}

.stats{
    color: #7482FF !important;
    padding-right: 10px;
}

.icon{
    width: 20px !important;
    height: auto !important;
}

.topLevelInfoContainer{
    padding-top: 25px;
    padding-bottom: 0;
}

.ratingContainer{
    display: inline-flex;
    align-items: baseline;
}

.rating{
    font-size: 17.68px;
    color: #7482FF;
}

.numberOfRatings{
    font-size: 14px;
    color: #818EB1;
}

.ratingDenominator{
    margin-right: 8px;
    font-size: 14px;
    color: #7482FF;
}

.price {
    overflow-x: hidden;
    padding-left: 10px;
    margin-left: auto;
    order: 2;
    color: #7482FF;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    height: fit-content;
}

.bottomSection{
    display: flex;
    align-items: center;
    height: 44px;
}

.sideBarButton{
    margin-bottom: 15px !important;
    width: 100% !important;
    padding: 10px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.sideBarButton[disabled]{
    color: #818EB1 !important;
    border-color: #818EB1 !important;
}

.otherActionIcon{
    margin-right: 6px;
}

.otherActionButton{
    cursor: pointer;
    width: fit-content;
    color: #818EB1;
    display: flex;
    align-items: center;
    align-self: center;
}

.authorActionContainer{
    display: flex;
    flex-direction: row;
    padding-top: 19px;
    padding-bottom: 19px;
}


.notesActionButton{
    width: 64px;
    margin: auto;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    color: #818EB1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.notAuthoredActionButton{
    font-size: 13px;
}

.notesActionButton:hover{
    color: #333C53;
}

.firstAuthorAction{
    margin-left: 0;
}

.middleActionButton{
}

.lastAuthorAction{
    margin-right: 0;
    float: right;
}

.authorActionIcon{
    margin-bottom: 11px;
}

.loadingButton{
    pointer-events: none;
}

.otherActionButton:hover{
    color: #333C53;
}

.otherActionButton p {
    margin: 0;
}

.reportButton{
    margin-top: 19px;
}

.contactButton{
    margin-top: 12px;
}

.previewStatusContainer{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.previewStatusMessage{
    margin-top: 10%;
    max-width: 70%;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    color: #333C53;
    text-align: center;
}

.endOfPreviewMessage{
    margin-top: 20px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 0.9vw;
    color: #333C53;
    text-align: center;
}

.previewSkeleton{
    background-color: #E3E3EE !important;
}

.circularProgress{
    color: #7482FF;
    margin-top: 50px;
}

@media only screen and (max-width: 480px) {
    .container{
        display: flex;
        flex-direction: column;
        width: 90% !important;
        margin-top: 20px;
    }

    .divider{
        width: 90vw !important;
    }

    .sideBar{
        width: -webkit-fill-available;
    }

    @-moz-document url-prefix() {
        .sideBar {
            width: -moz-available;
        }
    }

    .sideBarButton{
        margin-bottom: 15px !important;
    }
}