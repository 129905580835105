.verifyContainer{
    max-width: 90%;
    width: 420px;
    margin-top: 35px;
    border-radius: 11px !important;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.submitButton{
    width: fit-content !important;
}