.linkText{
    cursor: pointer;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #7482FF;
    width: auto;
}

.previewSubtitles{
    margin-bottom: 5px !important;
}

.header{
    margin-bottom: 5px !important;
}

.fieldContainer {
    margin-bottom: 100px !important;
}

.fieldCheckbox{
    margin-bottom: 75px !important;
}

.fileName{
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-wrap: none;
}

.fileNameContainer{
    width: 90%;
}

.previewField{
    margin-top: 25px !important;
}

.uploadIcon{
    height: 60px !important;
    width: auto !important;
    color: #818EB1;
}

.currencyField{
    width: 40%;
}

.stripeButton{
    width: fit-content;
}

.connectMessageText{
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    color: #333C53 !important
}

.checkboxText{
    color: #333C53 !important
}

.submitButton{
    margin-top: 50px !important;
}

.tabs{
    position: fixed;
    top: 13vh;
    left: max(14vw, calc(calc(100vw - 1361px)/2));
    border-right: 1px solid #C4C4C4 !important;
}

.tab{
    height: 64px;
    padding-right: 45px !important;
    font-size: 17.33px !important;
    font-weight: 600 !important;
}

@media only screen and (max-width: 1700px) {
    .tab{
        height: 50px;
        padding-right: 40px !important;
        font-size: 15px !important;
        font-weight: 600 !important;
    }
}

@media only screen and (max-width: 1500px) {
    .tabs {
        display: none !important;
    }
}
