:root {
    --card-width: 316px;
    --blue-color: #6CCAFF;
}

.firstInRowCard{
    margin-left: 0 !important;
}
.userGenText{
    margin-bottom: 0px !important;
}

.notesTitle{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.infoContainer{
    color: var(--blue-color);
}

.thumbnail{
    width: var(--card-width) !important;
    object-fit: cover !important;
    object-position: 100% 0 !important;
    opacity: 0.3;
    background-color: white;
}

.card{
    color: #818EB1 !important;
}

.price {
    padding-left: 20px;
    margin-left: auto;
    padding-right: 10px;
    order: 2;
    color: #7482FF;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    height: fit-content;
    overflow-x: hidden;
}

.card:hover .actionArea .thumbnail{
    transition: opacity 0.1s ease-in-out;
    opacity: 1 !important;
}


.ratingContainer{
    margin-left: 8px !important;
    display: inline-flex;
    align-items: baseline;
}

.stats{
    padding-left: 10px;
    color: #7482FF !important;
}

.icon{
    width: 20px !important;
    height: auto !important;
}

.thumbnailSkeleton{
    height: 144px !important;
    background-color: #E3E3EE !important;
}

.lineSkeleton{
    background-color: #E3E3EE !important;
    margin-bottom: 10px !important;
    height: 12px !important;
}

.cardContentSkeleton{
    height: 72px !important;
}

.schoolSkeleton{
    width: 40%;
}


@media only screen and (max-width: 1412px) {
    .thumbnail{
        width: 280px !important;
    }
}

@media only screen and (max-width: 480px) {
    .thumbnail{
        width: 316px !important;
    }
}


