.logoWithText{
    user-select: none;
    width: 254.69px;
    height: 35.97px;
}

.logoImg{
    cursor: pointer;
}

.toolbar{
    margin: auto;
    width: 72% !important;
    max-width: 1361px !important;
    gap: 34px;
    padding: 0 !important;
}

.appbar{
    background: linear-gradient(45deg,#C0C7FE, #BE8BFF);
    box-shadow: none !important;
    height: 64px !important;
    width: 100vw !important;
}

.homeAppBar{
    position: absolute !important;
    top: 0 !important;
    background: transparent !important;
}


.buttonsContainer button{
    margin: auto !important;
}

.buttonsContainer button:first-child{
    margin-left: 0 !important;
}

.buttonsContainer button:last-child{
    margin-right: 0 !important;
}

.buttonsContainer{
    display: flex;
    flex-wrap: nowrap;
    min-width: 180px !important;
}

.signInButton{
    white-space: nowrap !important;
    text-transform: none !important;
    font-family: 'DM Sans', sans-serif !important;
    font-weight: Bold !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.signUpButton{
    color: #1C212E !important;
    background-color: white !important;
    margin-left: 20px !important;
}

.arrow{
    padding-left: 4px;
    height: 43%;
}

.searchbar{
    width: -webkit-fill-available;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    background: url(../assets/times.svg) no-repeat 50% 50% !important;
}


.searchInput{
    width: 100% !important;
   
}

@-moz-document url-prefix() {
    .searchbar {
        width: -moz-available;
    }
}

.hamburgerButton{
    padding: 0px !important;
}

.hamburgerButton>svg{
    width: 30px !important;
    height: 30px !important;
}

.accountCircle{
    margin-right: 0 !important;
}

.subMenuNav{
    margin-top: 12px !important;
    border-radius: 0 !important;
}

@media only screen and (max-width: 900px) {
    .toolbar{
        width: 80% !important;
        gap: 20px;
    }

}

@media only screen and (max-width: 480px) {
    .toolbar{
        width: 90% !important;
        gap: 20px;
    }
}