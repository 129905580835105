.notesContainer{
    width: 72% !important;
    max-width: 1361px !important;
    margin: auto;
    margin-top: 43px;
    margin-bottom: 43px;
    display: grid;
    justify-content: center;
    gap: 32px;
    grid-template-columns: repeat(auto-fill, 316px);
}

.algoliaLogoContainer{
    width: 72% !important;
    max-width: 1361px !important;
    margin: auto !important;
    margin-top: -15px !important;
}

.seeMoreContainer{
    width: 100%;
    display: grid;
    justify-items: center;
}

.noNotesContainer{
    width: 100%;
    display: grid;
    justify-items: center;
}

.noNotes{
    margin-top: 62px;
    font-family: 'Inter', sans-serif;
    color: #333C53;
    font-size: 30px;
    text-align: center;
}

.browseAll{
    user-select: none;
    font-family: 'Inter', sans-serif;
    color: #333C53;
    font-size: 24px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}

.seeMore{
    font-weight: bold !important;
    color: #7482FF !important;
}

@media only screen and (max-width: 1412px) {
    .notesContainer{
        grid-template-columns: repeat(auto-fill, 280px);
    }
}
@media only screen and (max-width: 480px) {
    .notesContainer{
        grid-template-columns: repeat(auto-fill, 316px);
    }
}
