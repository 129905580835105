.userGenText{
    text-align: center;
    margin-top: 50px !important;
    margin-bottom: 30px !important;
}
.container{

}

.dashboardGrid{
    margin:auto;
}

.tabsContainer{
    margin-top: 30px;
    width: 100%;
    display: grid;
    justify-items: center;
}
