.fieldContainer {
    margin-bottom: 0px !important;
}

.walletHeader{
    margin-top: 100px;
}

.deleteAccountButton{
    color: white !important;
    margin-top: 20px !important;
}

:hover.deleteAccountButton{
    background-color: rgb(255, 56, 56) !important;
}

.changePasswordButton{
    margin-top: 20px !important;
}

:hover.changePasswordButton{
    background-color: #d3d3d3 !important;
}