.topLevelContainer{
    width: 100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #818EB1;
}

.searchContainer {
    /*the container must be positioned relative:*/
    width: 100%;
    display: inline-block;
    position: relative;
    font-family: Inter, sans-serif;
}

.searchInput {
    position: relative;
    z-index: 3;
    box-sizing:border-box;
    padding-left: 20px;
    border-width:0px !important;
    border:none;
    font-size: 18px;
    color: #333C53;
    border-radius: 12px 12px 12px 12px;
    background: white;
    height: 50px;
    width: 100%;
}


.searchInput:focus{
    outline: none !important;
}

.autocompleteItems {
    position: absolute;
    z-index: 2;
    /*position the autocomplete items to be the same width as the container:*/
    top: calc(100% - 12px);
    left: 0;
    right: 0;
    display: none;
}

.autocompleteItems.active{
    display: block;
}

.autocompleteItems div {
    color: #333C53;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 27px;
    cursor: pointer;
    background-color: #F3F3F3;
    border-radius: 0 0 12px 12px;
}
.autocompleteItems div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}


.tileContainer {
    box-sizing: border-box;
    padding-top: 69px;
    padding-bottom: 69px; 
    padding-left: min(89px, 4.6%);
    padding-right: min(89px, 4.6%);
    width: 72%;
    max-width: 1361px;
    margin: 60px auto;
    background-color: white;
    border-radius: 12px;
}

.statsTile{
    height: 340px;
}

.sellersTile{
    height: 480px;
}

.tileInnerContainer{
    display: flex;
    width: 100%;
    height: 100%;
}

.tileInnerContainer>:first-child{
    margin-right: auto;
}

.statsContainer{
    height: 100%;
    width: 55%;
    display: flex;
    align-items: center;
}

.statsContainer>*{
    margin: auto;
}
.counterContainer{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.counter{
    width: fit-content;
    font-family: DM Sans, sans-serif;
    font-weight: bold;
    color: #1C212E;
    font-size: max(min(85px, 5vw), 60px);
}

.counterLabel{
    font-family: DM Sans, sans-serif;
    font-weight: 500;
    color: #7482FF;

}
.statsInfoContainer{
    display: flex;
    height: 100%;
    width: 39.5%;
    align-items: center;
}

.statsInfo{
    width: 100%;
    font-family: Inter, sans-serif;
    text-align: left;
    font-size: max(min(1.4vw, 21.5px), 17px);
    max-width: 468px;
    color: #818EB1;
}
.landingTileContainer{
    padding-top: 64px;
    box-sizing: border-box;
    width: 100%;
    background: linear-gradient(to right bottom, #C0C7FE, #BE8BFF) !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    height: 67vh;
    max-height: 649.22px;
    min-height: 500px;
    align-items: center;
}

.landingTileInnerContainer{
    max-width: 1361px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.landingTileInnerContainer>*{
    margin: auto;
}

.uaFlag{
    height: 0.76em;
    width: auto;
}

.title{
    color: #FAFAFA;
    font-family: DM Sans, sans-serif;
    font-weight: bold;
    font-size: max(min(70px, 5vw), 46px);
    margin-bottom: 45px;
    text-align: center;
}

.subtitle{
    text-align: center;
    text-decoration: none;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #FAFAFA;
    font-family: Inter, sans-serif;
    max-width: 850px;
    font-weight: 400;
    font-size: max(min(27px, 2.15vw), 17px);
}

:hover.subtitle{
    text-decoration: underline;
}

.search{
    border: 1px solid #DDDDDD !important;
    padding: 0 0 0 0;
    margin-top: 90px !important;
    margin-bottom: auto !important;
    width: 100%;
    max-width: 846px;
    border-radius: 15px 15px 15px 15px;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
}

.buttonContainer{
    display: flex;
    gap: 45px;
}

.introTileLeftContainer{
    height: 100%;
    max-width: 578px;
    display: flex;
    flex-direction: column;
}

.sellersTileLeftContainer{
    height: 100%;
    max-width: 457px;
    display: flex;
    flex-direction: column;
}

.sellersTileLeftContainer>:first-child{
    margin-bottom: auto;
}

.sellersTileLeftContainer>:last-child{
    margin-top: auto;
}

.sellersTileTitle{
    font-family: DM Sans, sans-serif;
    font-weight: bold;
    font-size: min(43px, 3vw);
    text-align: left;
}

.sellersTileSubtitle{
    font-family: Inter, sans-serif;
    text-align: left;
    font-size: max(min(1.4vw, 19px), 17px);
    color: #818EB1;
    font-weight: 400;
}

.introSubtitle{
    font-family: Inter, sans-serif;
    text-align: left;
    font-size: max(min(1.4vw, 19px), 16px);
    color: #818EB1;
    font-weight: 400;
    margin-bottom: 27px;
}

.introFirstPagaragraph{
    font-weight: 600;
    margin-top: 27px;
}


.barGraph{
    margin-left: auto;
    width: 51%;
}

.networkImage{
    margin-left: auto;
    width: 39%;
}

.bottomButtonContainer{
    margin-top: 20px;
    flex-direction: column;
    gap: 23px;
    display: none;
}

@media screen and (max-width:1760px){
    .title{
        font-size: max(min(70px, 3.5vw), 46px);
    }

    .subtitle{
        font-size: max(min(27px, 1.75vw), 17px);
    }
}

@media screen and (max-width:1420px){
    .search{
        margin-top: 70px !important;
    }

    .sellersTileLeftContainer{
        max-width: 400px;
    }

    .sellersTileSubtitle{
        width: 80%;
    }
}

@media screen and (max-width:1300px){
   .sellersTile{
       height: 400px;
   }
}

@media screen and (max-width:1200px) {
    .sideButtonContainer{
        gap: 20px;
    }
}

@media screen and (max-width:1150px) {
    .sideButtonContainer{
        flex-direction: column;
        gap: 10px;
    }

    .sideButtonContainer>*{
        width: 80% !important;
    }
}


@media screen and (max-width:900px){

    .landingTileContainer{
        height: 50vh;
        min-height: 500px;
    }
    .landingTileInnerContainer{
        width: 80%;
    }

    .subtitle{
        max-width: 500px;
        font-size: 20px;
    }
    .sellersTile{
        height: fit-content;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .sellersTileTitle{
        font-size: 30px;
    }
    .sellersTileLeftContainer{
        width: 100%;
        max-width: 1000px;
        align-items: center;
        justify-items: center;
        gap: 30px
    }

    .sideButtonContainer{
        display: none;
    }

    .sellersTileSubtitle{
        max-width: 1000px;
        font-size: 20px;
    }

    .statsInfo{
        max-width: 1000px;
        font-size: 20px;
    }

    .sellersTileLeftContainer>*{
        text-align: center;
    }
    .barGraph{
        width: 90%;
        float: none;
        margin: auto;
    }
    .networkImage{
        width: 70%;
        float: none;
        margin: auto;
        margin-bottom: 20px;
    }
    .statsContainer{
        width: 100%;
    }
    .tileInnerContainer{
        flex-direction: column;
        gap: 90px;
    }
    .introTileInnerContainer{
        gap: 50px;
    }
    .statsInfoContainer{
        width: 100%;
    }
    .statsTile {
        height: auto;
    }
    .statsInfo{
        align-items: center;
        margin: auto;
        text-align: center;
        width: 80%;
    }
    .bottomButtonContainer{
        display: flex;
    }
}

@media screen and (max-width:540px){
    .statsContainer{
        flex-direction: column;
        width: 100%;
        gap: 50px;
    }
    .landingTileContainer{
        height: 67vh;
        min-height: 500px;
    }
    .sellersTileInnerContainer{
        gap: 50px;
    }
    .sellersTile{
        padding-top: 30px;
        padding-bottom: 30px;
    }


    .sellersTileSubtitle{
        width: 100%;
        font-size: 17px;
    }

    .landingTileInnerContainer{
        width: 90%;
    }
    .subtitle{
        width: 90%;
        font-size: 17px;
        font-family: Inter, sans-serif !important;
    }
    .title{
        margin-bottom: 30px !important;
    }

    .search{
        width: 100% !important;
        margin-top: 60px !important;
    }

    .statsInfo{
        font-size: 17px;
    }

    .tileContainer{
        width: 95%;
    }

    .search{
        width: 90%;
    }

}



