.formContainer{
    max-width: 72%;
    width: 750px;
    margin-top: 35px;
    border-radius: 11px !important;
    box-sizing: border-box;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: 35px;
    padding-right: 35px;
    background-color: white;
}

.authContainer{
    max-width: 90%;
    width: 420px;
    margin-top: 35px;
    border-radius: 11px !important;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.submitConfirmationHeader{
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    color: #333C53;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.multiline{
    width: 100%;
}

.socialContainer{
    max-width: 90%;
    width: 420px;
    margin-top: 20px;
    border-radius: 11px !important;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header{
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    color: #333C53;
    font-weight: 600;
    margin-bottom: 28px;
}

.recoverHeader, .noMarginHeader{
    margin-bottom: 0px !important;
}

.fieldContainer{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
}

.authFieldContainer{
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.fieldRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 23px;
}

.field{
    width: -webkit-fill-available;
    max-width: calc(50% - 11.5px);
}

.wordCountProgress{
    height: 2px !important;
}

.forgotPassword{
    cursor: pointer;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #818EB1;
}

.forgotPassword:hover{
    color: #7482FF;
}

.checkboxContainer{
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

}

.authButton{
    margin-top: 15px !important;
    height: 43.5px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #7482FF !important;
    border-color: #7482FF !important;
}

.authButton[disabled]{
    color: #818EB1 !important;
    border-color: #818EB1 !important;
}

.submitButton{
    width: 101.39px !important;
    height: 43.5px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #7482FF !important;
    border-color: #7482FF !important;

}

.submitButton[disabled]{
    color: #818EB1 !important;
    border-color: #818EB1 !important;
}

@-moz-document url-prefix() {
    .field {
        width: -moz-available;
    }
}

.topLevelContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wideField{
    max-width: 100%;
}

.tabsContainer{
    margin: auto;
}

.checkboxHelperText{
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #A7B3D1;
}

.subtitle{
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #A7B3D1;
    width: 100%;
    margin-bottom: 28px;
}

@media only screen and (max-width: 800px) {
    .formContainer{
        width: 90%;
        max-width: 100%;
    }

    .fieldRow{
        flex-direction: column;
    }

    .field{
        max-width: 100%;
    }
}

@media only screen and (max-width: 540px) {
    .formContainer{
        width: 95%;
        padding-left: 25px;
        padding-right: 25px;
    }
}

